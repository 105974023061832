import React from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import Layout from "../layout"
import theme from "../theme"
import { t, currentLanguage, Language } from "../i18n"
import { CrtPage } from "../question"
import { RoundButton } from "../button"
import backgroundImage from "../assets/bg404.jpg"

export default () => (
  <Layout>
    <CrtPage
      bg={backgroundImage}
      animate="undefined"
      exit="undefined"
      style={{ paddingTop: 100 }}
    >
      <BigText>{t("404.heading")}</BigText>
      <SubHeader>{t("404.subHeading")}</SubHeader>
      <BackRoundButton to="/resources">
        {t("404.backButtonLabel")}
      </BackRoundButton>
    </CrtPage>
  </Layout>
)

const BigText = styled.p({
  fontSize: "5rem",
  fontFamily: theme.fonts.special,
  textTransform: "uppercase",
  margin: "3rem 0 4rem 0",
  [theme.mq.desktophd]: {
    fontSize: "5rem",
  },
  [theme.mq.desktop]: {
    fontSize: "5rem",
  },
  [theme.mq.tablet]: {
    fontSize: "4rem",
  },
  [theme.mq.mobile]: {
    fontSize: "3rem",
    margin: "0 0 2rem 0",
    textAlign: "center",
  },
})

const SubHeader = styled.p({
  fontSize: "3rem",
  fontFamily: theme.fonts.header,
  textTransform: "uppercase",
  margin: "0 0 4rem 0",
  [theme.mq.desktophd]: {
    fontSize: "3rem",
  },
  [theme.mq.desktop]: {
    fontSize: "3rem",
  },
  [theme.mq.tablet]: {
    fontSize: "2rem",
  },
  [theme.mq.mobile]: {
    fontSize: "2rem",
    margin: "0 1rem 2rem 1rem",
    textAlign: "center",
  },
})

const BackRoundButton = styled(RoundButton)({
  width: 200,
  height: 200,
  fontSize: "1.5rem",
  marginTop: "3rem",
  [theme.mq.tablet]: {
    width: 150,
    height: 150,
    marginTop: "2rem",
  },
  [theme.mq.mobile]: {
    width: 100,
    height: 100,
    fontSize: "0.7rem",
    marginTop: "3rem",
  },
})
